<template>
    <b-nav-item-dropdown
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user pb-75"
      id="dropdown-1"
      ref="dropdown"
    >
      <template #button-content>
        <div class="d-sm-flex d-none user-nav">
          <p class="user-name font-weight-bolder mb-0">
            {{ usuario.nombre ? usuario.nombre : usuario.name ? usuario.name : '' }}
          </p>
          <span class="user-status text-capitalize">{{ localRole }}</span>
        </div>
        <b-avatar
          size="40"
          :src="usuario.foto ? usuario.foto : ''"
          variant="light-primary"
          :text="avatarText(usuario.nombre ? usuario.nombre : usuario.name ? usuario.name : '')"
          badge
          class="badge-minimal"
          badge-variant="success"
        >
          <feather-icon
            v-if="(!usuario.nombre && !usuario.name) && !usuario.foto"
            icon="UserIcon"
            size="22"
          />
        </b-avatar>
      </template>

      <b-dropdown-item
        :to="{ name: 'pages-account-setting' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="UserIcon"
          class="mr-50"
        />
        <span>Profile</span>
      </b-dropdown-item>

      <!-- <b-dropdown-item
        :to="{ name: 'pages-settings-quickbooks' }"
        link-class="d-flex align-items-center"
        v-if="!clinicianOrClinicianViewer"
      >
        <feather-icon
          size="16"
          icon="SettingsIcon"
          class="mr-50"
        />
        <span>Settings</span>
      </b-dropdown-item> -->

      <b-dropdown-item
        :to="{ name: 'pages-faq' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="HelpCircleIcon"
          class="mr-50"
        />
        <span>Help</span>
      </b-dropdown-item>

      <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="logout"
      >
        <feather-icon
          size="16"
          icon="LogOutIcon"
          class="mr-50"
        />
        <span>Sign out</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'
import { db, auth } from '@/firebase'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      // userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
      belongsTeams: '',
      show: false,
      clinicianOrClinicianViewer: false,
      dataSpecialist: false,
      adminOrManageUsers: false,
      loadDataPage: false,
      localRole: '',
    }
  },
  computed: {
    ...mapState(['usuario'])
  },
  methods: {
    ...mapActions(['cerrarSesion']),
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Reset ability
      // this.$ability.update(initialAbility)

      // Redirect to login page
      // this.$router.push({ name: 'auth-login' })
      this.cerrarSesion()
    },
  },
  created() {
    const user = auth.currentUser
    if(user) {
      db.collection('usuarios').doc(user.uid).get()
      .then(userDoc => {
        if(userDoc.data().role === 'admin') {
            this.adminOrManageUsers = true
            this.localRole = 'admin'
        } else if(userDoc.data().role === 'staff') {
            if(userDoc.data().permissions.includes('manage users')) {
                this.adminOrManageUsers = true
                this.localRole = 'Manage Users'
            } else if(userDoc.data().permissions.includes('data specialist')) {
                this.dataSpecialist = true
                this.localRole = 'Data Specialist'
            } else if(userDoc.data().permissions.includes('clinician viewer')) {
                this.clinicianOrClinicianViewer = true
                this.localRole = 'Clinician Viewer'
            } else {
                this.clinicianOrClinicianViewer = true
                this.localRole = 'Staff'
            }
        } else if(userDoc.data().role === 'clinician' || userDoc.data().role === 'clinician-staff') {
            this.clinicianOrClinicianViewer = true
            this.localRole = 'Clinician'
        }
        
        this.loadDataPage = true
      })
    } else {
      this.logout()
    }
  }
}
</script>
