<template>
  <div class="clearfix mb-0" style="font-size: .8em; color: #aaa; text-align: center; display: grid;">
    <div>
      <b-link
        href="mailto:portalhelp@nycpg.org"
        style="color: #fff; text-align: center; font-size: 14px; line-height: 1.42857;"
        class="mb-50"
      >
        Contact us
      </b-link> • 
      <b-link
        :to="{name:'terms-of-use'}"
        style="color: #fff; text-align: center; font-size: 14px; line-height: 1.42857;"
        class="mb-50"
      >
        Terms of Use
      </b-link> • 
      <b-link
        :to="{name:'privacy-policy'}"
        style="color: #fff; text-align: center; font-size: 14px; line-height: 1.42857;"
        class="mb-50"
      >
        Privacy Policy
      </b-link>
    </div>
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT © {{ new Date().getFullYear() }}
      New York Council on Problem Gambling
      <span class="d-none d-sm-inline-block">, All Rights Reserved</span>
    </span>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
