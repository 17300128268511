<template>
  <li
    v-if="canViewVerticalNavMenuLink(item)"
    class="nav-item"
    :class="{
      'active': isActive,
      'disabled': item.disabled
    }"
  >
    <b-link
      v-bind="linkProps"
      class="d-flex align-items-center"
      v-if="item.route === 'csv-to-json'"
      :to="user && user.uid ? {name: 'edit-clinician', params: {id: user.uid}} : ''"
    >
      <feather-icon :icon="item.icon || 'CircleIcon'" />
      <span class="menu-title text-truncate">
        {{ t(item.title) }}
        <!-- <b-badge
          pill
          :variant="'primary'"
          class="mr-1 ml-auto"
          v-if="item.title === 'Users' && counterNewLeads >= 1"
        >
          {{counterNewLeads}}
        </b-badge> -->
      </span>
      <!-- <b-avatar
        variant="success"
        :text="item.title"
      /> -->
      
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
    <b-link
      v-bind="linkProps"
      class="d-flex align-items-center"
      v-else
    >
      <feather-icon :icon="item.icon || 'CircleIcon'" />
      <span class="menu-title text-truncate">
        {{ t(item.title) }}
        <!-- <b-badge
          pill
          :variant="'primary'"
          class="mr-1 ml-auto"
          v-if="item.title === 'Users' && counterNewLeads >= 1"
        >
          {{counterNewLeads}}
        </b-badge> -->
      </span>
      <!-- <b-avatar
        variant="success"
        :text="item.title"
      /> -->
      
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
import { BLink, BBadge } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'
import { BAvatar } from 'bootstrap-vue'
import { auth,db } from '@/firebase'

export default {
  components: {
    BLink,
    BBadge,
    BAvatar,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      user: null,
      // counterNewLeads: 0,
      // userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    }
  },
  created() {
    this.user = auth.currentUser
  }
}
</script>
