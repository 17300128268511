export default [
  {
    title: 'My Dashboard',
    icon: 'HomeIcon',
    route: 'csv-to-json',
  },
  {
    title: 'Reports',
    icon: 'ActivityIcon',
    tagVariant: 'light-danger',
    children: [
      {
        title: 'Clinician Report',
        route: 'reports-clinician',
        icon: 'FolderIcon',
      },
    ]
  },
]
