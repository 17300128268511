export default [
  {
    title: 'Clinicians',
    icon: 'UsersIcon',
    tagVariant: 'light-danger',
    children: [
      {
        title: 'All Clinicians',
        route: 'all-clinicians',
        icon: 'UsersIcon',
      },
    ]
  },
  // {
  //   title: 'Reports',
  //   icon: 'ActivityIcon',
  //   tagVariant: 'light-danger',
  //   children: [
  //     {
  //       title: 'Clinician Report',
  //       route: 'reports-clinician',
  //       icon: 'FolderIcon',
  //     },
  //   ]
  // },
]