export default [
  {
    title: 'Clinicians',
    icon: 'UsersIcon',
    children: [
      {
        title: 'All Clinicians',
        route: 'all-clinicians',
        icon: 'UsersIcon',
      },
      {
        title: 'Claims',
        icon: 'MenuIcon',
        children: [
          {
            title: 'All Claims',
            route: 'claims',
            icon: 'ArchiveIcon',
          },
          {
            title: 'Approved Claims',
            route: 'approved-claims',
            icon: 'CheckSquareIcon',
          },
        ]
      },
      {
        title: 'All Client Activity',
        route: 'client-activities',
        icon: 'BarChart2Icon',
      },
    ],
  },
  {
    title: 'Clients',
    route: 'clients',
    icon: 'BriefcaseIcon',
    action: 'read',
    resource: 'ACL',
  },
  {
    title: 'Reports',
    icon: 'ActivityIcon',
    tagVariant: 'light-danger',
    children: [
      {
        title: 'General',
        route: 'reports-general',
        icon: 'AlertCircleIcon',
      },
      {
        title: 'Call Data',
        route: 'reports-call-reports',
        icon: 'PhoneCallIcon',
      },
      {
        title: 'Active Treatment',
        route: 'reports-active-treatment',
        icon: 'HeartIcon',
      },
      {
        title: 'Discharge Data',
        route: 'reports-discharge-data',
        icon: 'LogOutIcon',
      },
      {
        title: 'Payment/Insurance Utilization',
        route: 'reports-insurance-utilization',
        icon: 'BriefcaseIcon',
      },
      {
        title: 'Finance Information',
        route: 'reports-finance-information',
        icon: 'TrendingUpIcon',
      },
      {
        title: 'Clinician Report',
        route: 'reports-clinician',
        icon: 'FolderIcon',
      },
    ]
  },
  {
    title: 'Notifications',
    route: 'notifications',
    icon: 'BellIcon',
    // tag: 'new',
    action: 'read',
    resource: 'ACL',
  },
  {
    title: 'Staff Members',
    route: 'all-staff-members',
    icon: 'ToolIcon',
    action: 'read',
    resource: 'ACL',
  },
]
  