<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded mb-1">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <li class="nav-item m-auto">
            <b-link
              class="navbar-brand"
              :to="{ name: 'access-control' }"
            >
              <span class="brand-logo">
                <b-img-lazy
                  style="height: 40px; width: 100%; max-width: 100%;"
                  :src="collapseTogglerLogo"
                  alt="logo"
                  :style="collapseTogglerStyleLogo"
                />
              </span>
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <vertical-nav-menu-items
        v-if="(isClinician || isClinicianStaff) && (statusReferral === 'active' || statusReferral === 'inactive')"
        :items="(programClinician === 'PGRC - Group Practice' || programClinician === 'PGRC - OASAS Program' || programClinician === 'PGRC - Private Practitioner') && !isClinicianStaff ? navMenuItemsAccessClinicianActive : isClinicianStaff ? navMenuItemsAccessClinicianActiveStaffPGRC  : navMenuItemsAccessClinicianActiveOASAS"
        class="navigation navigation-main"
      />
      <vertical-nav-menu-items
        v-else
        :items="adminOrManageUsers ? navMenuItemsAccessAdmin : dataSpecialist ? navMenuItemsAccessDataSpecialist : clinicianOrClinicianViewer ? navMenuItemsAccessClinicianViewer : navMenuItemsAccessClinician"
        class="navigation navigation-main"
      />
      <!-- navMenuItemsAccessClinicianViewer -->
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import navMenuItemsAccessAdmin from '@/navigation/vertical/admin'
import navMenuItemsAccessDataSpecialist from '@/navigation/vertical/access-control'
import navMenuItemsAccessClinicianViewer from '@/navigation/vertical/index'
import navMenuItemsAccessClinician from '@/navigation/vertical/clinician'
import navMenuItemsAccessClinicianActive from '@/navigation/vertical/active-clinician'
import navMenuItemsAccessClinicianActiveOASAS from '@/navigation/vertical/indexOASAS'
import navMenuItemsAccessClinicianActiveStaffPGRC from '@/navigation/vertical/indexStaffPGRC'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg, BAvatar, BImgLazy,  } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'
import { avatarText } from '@core/utils/filter'
import { mapActions, mapState } from "vuex"
import { auth, db } from '@/firebase'

export default {
  data() {
    return {
      userRole: '',
      imagenUser: '',
      statusReferral: '',
      userData: '',
      clinicianOrClinicianViewer: false,
      isClinician: false,
      dataSpecialist: false,
      adminOrManageUsers: false,
      programClinician: '',
      isClinicianStaff: false
    }
  },
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
    BAvatar,
    BImgLazy,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapState(['usuario'])
  },
  setup(props) {
    // console.log(this.usuario)

    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))
    let collapseTogglerLogo = computed(() => (collapseTogglerIcon.value === 'unpinned' ? appOnlyLogoImage : appLogoImage))
    const collapseTogglerStyleLogo = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'height:35px' : 'initial'))
    collapseTogglerLogo = computed(() => (!isMouseHovered.value && collapseTogglerIcon.value === 'unpinned' ? appOnlyLogoImage : appLogoImage))

    // App Name
    const { appName, appLogoImage, appOnlyLogoImage } = $themeConfig.app

    return {
      navMenuItemsAccessAdmin,
      navMenuItemsAccessDataSpecialist,
      navMenuItemsAccessClinicianViewer,
      navMenuItemsAccessClinician,
      navMenuItemsAccessClinicianActive,
      navMenuItemsAccessClinicianActiveOASAS,
      navMenuItemsAccessClinicianActiveStaffPGRC,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,
      collapseTogglerLogo,
      collapseTogglerStyleLogo,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // avatar
      avatarText,

      // App Name
      appName,
      appLogoImage,
      appOnlyLogoImage,
    }
  },
  created() {
    const user = auth.currentUser
    if(user) {
      db.collection('usuarios').doc(user.uid).get()
      .then(userDoc => {
        if(userDoc.data().role === 'admin') {
          this.adminOrManageUsers = true
        } else if(userDoc.data().role === 'staff') {
          if(userDoc.data().permissions.includes('manage users')) {
            this.adminOrManageUsers = true
          } else if(userDoc.data().permissions.includes('data specialist')) {
            this.dataSpecialist = true
          } else if(userDoc.data().permissions.includes('clinician viewer')) {
            this.clinicianOrClinicianViewer = true
          } else {
            this.clinicianOrClinicianViewer = true
          }
        } else if(userDoc.data().role === 'clinician') {
          this.isClinician = true
          this.programClinician = userDoc.data().program ? userDoc.data().program : ''
        }

        if(userDoc.data().role === 'clinician-staff') {
          this.isClinicianStaff = true
        }

        // status
        this.statusReferral = userDoc.data().statusReferral ? userDoc.data().statusReferral : ''
        
        this.userData = userDoc.data()
      })
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>

<style>
.brand-logo-sidebar {
  white-space: initial;
  color: #fff;
  font-size: 1.2rem;
  padding-right: 20px;
  padding-left: 20px;
  width: 170px;
  display: block;
  margin: auto;
}
/* .navigation li, .menu-title {
  white-space: initial !important;
} */
.navigation li .text-truncate {
  white-space: initial !important;
}
</style>
