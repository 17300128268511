export default [
  {
    title: 'My Dashboard',
    icon: 'HomeIcon',
    route: 'csv-to-json',
  },
  {
      title: 'Clients',
      route: 'clients',
      icon: 'BriefcaseIcon',
      action: 'read',
      resource: 'ACL',
  },
  {
    title: 'Notifications',
    route: 'notifications-clinician',
    icon: 'BellIcon',
    // tag: 'new',
    action: 'read',
    resource: 'ACL',
  },
]